@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Freehand&family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ephesis&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
/*
  Css Reset
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
  /* -webkit-user-select: none;  
  -ms-user-select: none; 
  user-select: none;   */
  scroll-behavior: smooth;
}
/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/

/*
  5. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  6. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  7. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
a {
  text-decoration: none;
}
/*
  8. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}
button {
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}
body {
  margin: 0;
  font-family: "Roboto", "Noto Color Emoji", "Freehand", "Ephesis",
    "Inria Serif","DM Serif Display", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  overflow-x: hidden;
  background: #f8f9fa;
}
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #8d8d8d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
